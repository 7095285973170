<template>
  <div>
    <!--begin::Card-->
    <OverlayLoader :is-submitting="loader.show" :message="loader.message" :timing="loader.timing">
      <!-- <div class="alert alert-info mb-5 p-5" role="alert">
        <h4 class="alert-heading">Informações importantes!</h4>
        <p>
          Para realizar o depósito de reais em sua carteira é muito simples, informe o valor no campo abaixo para que
          possamos gerar sua fatura de depósito.
        </p>
      </div> -->

      <!-- Form -->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <!-- Title Input -->
        <b-form-group label="Título do seu produto ou serviço:" label-for="input-title">
          <b-form-input
            id="input-title"
            v-model="$v.form.title.$model"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            :state="validateState('title')"
          />
          <b-form-invalid-feedback>O campo de titulo é obrigatório.</b-form-invalid-feedback>
        </b-form-group>

        <!-- Value input -->
        <b-form-group label="Preço:" label-for="input-value">
          <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
            <money
              id="input-value"
              v-model="$v.form.value.$model"
              v-bind="maskMoneyReal()"
              class="form-control form-control-solid h-auto py-5 px-6 rounded"
              :class="$v.form.value.$invalid ? 'is-invalid' : 'is-valid'"
              :state="validateState('value')"
            />
            <b-form-invalid-feedback v-if="!$v.form.value.minValue">Insira um valor</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>

        <!--begin::Action-->
        <div class="form-group d-flex justify-content-center border-top pt-10">
          <button
            ref="btn_submit"
            type="submit"
            class="btn btn-success font-weight-bold px-12 py-4 my-3 font-size-3 mx-4"
            :disabled="isSubmitting"
            :class="{ 'spinner spinner-light spinner-right': isSubmitting }"
          >
            Criar e Compartilhar
          </button>
        </div>
        <!--end::Action-->
      </b-form>
    </OverlayLoader>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
</style>

<script>
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { validationMessage } from 'vuelidate-messages';
import formMessages from '@/core/utils/validationMessages';
import { mapGetters } from 'vuex';
import OverlayLoader from '@/shared/content/OverlayLoader.vue';
import { Money } from 'v-money';

export default {
  name: 'NewPaymentLink',
  components: {
    OverlayLoader
  },
  mixins: [validationMixin],
  props: {
    activeItemStatusId: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      isSubmitting: false,
      loader: {
        show: false,
        message: '',
        timing: false
      },
      form: {
        title: null,
        value: 0
      }
    };
  },
  computed: {
    ...mapGetters(['currentStatus'])
  },

  validations: {
    form: {
      title: {
        required
      },
      value: {
        required,
        minValue: minValue(1)
      }
    }
  },

  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.isSubmitting = false;
        return;
      }

      this.loader.show = true;
      this.loader.timing = true;
      this.loader.message = 'Aguarde... Enquanto estamos formalizamos seu link de pagamento.';

      const form = {
        title: this.$v.form.title.$model,
        value: this.$v.form.value.$model
      };

      const submitButton = this.$refs['btn_submit'];

      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      // send register request
      await this.$store
        .dispatch('STORE_PAYMENT_LINK', form)
        .then((response) => {
          this.$swal({
            title: response.success,
            icon: 'success'
          });

          this.$bvModal.hide('modal-new-payment-link');
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');

          this.$emit('callMethodInParent');
        })
        .catch(() => {
          this.loader.show = false;
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validationMsg: validationMessage(formMessages)
  }
};
</script>
