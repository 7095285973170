<template>
  <div class="flex-row-fluid col-lg-9">
    <div class="card card-custom gutter-b">
      <div class="card-header py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">Meus Links de Pagamento</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">Acompanhe abaixo seus links de pagamento</span>
        </h3>
        <div class="card-toolbar">
          <a v-b-modal.modal-new-payment-link class="btn btn-success font-weight-bolder font-size-sm">
            <span class="svg-icon svg-icon-md svg-icon-white">
              <inline-svg src="/media/svg/icons/Communication/Add-user.svg" />
            </span>
            Novo Link de pagamento
          </a>
        </div>
      </div>
      <div class="card-body">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x"
          data-remember-tab="tab_id"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link text-warning"
              :class="{ active: isActive('pending') }"
              href="#pending"
              @click.prevent="setActive('pending', 1)"
            >
              <i class="fa fa-exclamation-triangle icon-1x text-warning pr-2"></i>
              Pendentes
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-success"
              :class="{ active: isActive('confirmed') }"
              href="#confirmed"
              @click.prevent="setActive('confirmed', 2)"
            >
              <i class="fa fa-check icon-1x text-success pr-2"></i>
              Confirmados
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-danger"
              :class="{ active: isActive('canceled') }"
              href="#canceled"
              @click.prevent="setActive('canceled', 4)"
            >
              <i class="fa fa-times icon-1x text-danger pr-2"></i>
              Cancelados
            </a>
          </li>
        </ul>

        <div>
          <b-table
            class="table table-head-custom table-vertical-center"
            :items="pageOfItems"
            :per-page="perPage"
            :fields="fields"
            :busy="isLoading"
            stacked="sm"
            hover
            show-empty
          >
            <!-- Empty table -->
            <template #empty>
              <EmptyTable message="Você ainda não criou nenhum link de pagamento." />
            </template>

            <!-- Busy table -->
            <template #table-busy>
              <BusyTable message="Aguarde enquanto carregamos seus links de pagamento." />
            </template>

            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(created_at)="row">
              <span>
                <div class="font-weight-bolder text-success mb-0 font-size-lg">
                  {{ $moment(row.item.created_at).format('DD/MM/YYYY') }}
                </div>
                <div class="text-muted">{{ $moment(row.item.created_at).format('hh:mm:ss') }}</div>
              </span>
            </template>

            <template #cell(id)="row">
              <span class="font-weight-bolder text-success mb-0 font-size-lg">{{ row.item.id }}</span>
            </template>

            <template #cell(value)="row">
              <span>
                {{ row.item.value | currencyReal() }}
              </span>
            </template>

            <template #cell(status)="row">
              <span class="badge" :class="'badge-' + row.item.status.class">{{ row.item.status.name }}</span>
            </template>

            <template #cell(actions)="row">
              <LinkPaymentActions :link-payment="row.item" />
            </template>
          </b-table>
          <pagination
            v-if="!isLoading && payment_link_list.length > 0"
            :items="payment_link_list"
            :page-size="perPage"
            @changePage="onChangePage"
          ></pagination>
        </div>
      </div>
    </div>
    <!-- Modal new Proposal -->
    <b-modal
      id="modal-new-payment-link"
      ref="modal-new-payment-link"
      title="Novo Link de Pagamento"
      size="xs"
      modal
      hide-footer
    >
      <NewPaymentLink :active-item-status-id="activeItemStatusId" @callMethodInParent="setActive('pending', 1)" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Pagination from '@/shared/content/widgets/table/Pagination.vue';
import EmptyTable from '@/shared/components/extras/EmptyTable';
import BusyTable from '@/shared/components/extras/BusyTable';
import NewPaymentLink from './NewPaymentLink';
import LinkPaymentActions from './LinkPaymentActions';

export default {
  name: 'ListPaymentLink',
  components: {
    NewPaymentLink,
    LinkPaymentActions,
    Pagination,
    EmptyTable,
    BusyTable
  },
  data() {
    return {
      isLoading: false,
      activeItem: 'pending',
      activeItemStatusId: 1,
      perPage: 10,
      fields: [
        { label: 'Data', key: 'created_at', sortable: true },
        { label: 'ID', key: 'id', sortable: true },
        { label: 'Titulo', key: 'title', sortable: true },
        { label: 'Valor', key: 'value', sortable: true },
        { label: 'Status', key: 'status', sortable: true },
        { label: 'Ações', key: 'actions', sortable: false }
      ],
      pageOfItems: []
    };
  },
  computed: {
    ...mapGetters(['payment_link_list'])
  },
  watch: {
    async payment_link_list() {
      this.pageOfItems = await this.payment_link_list;
    }
  },
  async mounted() {
    this.$store.dispatch('SET_BREADCRUMB', [{ title: 'Link de Pagamento' }]);
    this.isLoading = true;

    await this.$store.dispatch('GET_PAYMENT_LINK_LIST', { status_id: '1' });
    if (!this.isPagination) {
      this.pageOfItems = this.payment_link_list;
    }

    this.isLoading = false;
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    async setActive(menuItem, status_id) {
      this.isLoading = true;
      this.activeItem = menuItem;
      this.activeItemStatusId = status_id;
      await this.$store.dispatch('GET_PAYMENT_LINK_LIST', { status_id: status_id });
      this.isLoading = false;
    }
  }
};
</script>

<style></style>
