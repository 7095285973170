<template>
  <div>
    <b-button-group v-if="linkPayment.status.id == 1">
      <b-dropdown variant="info" right text="Compartilhar">
        <b-dropdown-item @click="copyUrl(linkPayment.payment_link)">Copiar Link</b-dropdown-item>
        <ShareNetwork
          network="whatsapp"
          :url="linkPayment.payment_link"
          title="Olá, gostaria de compartilhar este link de pagamento com você!"
          :description="'Esse link de pagamento é no valor de ' + linkPayment.value + '.'"
        >
          <b-dropdown-item>Enviar por WhatsApp</b-dropdown-item>
        </ShareNetwork>
      </b-dropdown>
      <b-button
        :ref="'btn_cancel_' + linkPayment.id"
        variant="danger"
        class="btn btn-danger btn-sm font-weight-bolder"
        @click="cancel(linkPayment.id)"
      >
        Cancelar
      </b-button>
    </b-button-group>

    <div v-else>--</div>
  </div>
</template>

<script>
export default {
  name: 'LinkPaymentActions',
  components: {},
  props: {
    linkPayment: {
      type: Object,
      default: null
    }
  },
  methods: {
    async cancel(payment_link_id) {
      this.$swal({
        title: 'Você tem certeza que deseja cancelar este link de pagamento ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Sim, cancelar!',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$swal({
            text: 'Aguarde enquanto cancelamos o link de pagamento...',
            icon: 'warning',
            showConfirmButton: false,
            dangerMode: true,
            allowOutsideClick: false
          });

          await this.$store
            .dispatch('CANCEL_PAYMENT_LINK', payment_link_id)
            .then((response) => {
              this.$swal({ title: response.success, icon: 'success' });
              this.$store.dispatch('GET_PAYMENT_LINK_LIST', {
                status_id: 1
              });
            })
            .catch((error) => {
              this.$swal({
                title: error,
                icon: 'error'
              });
            });
        }
      });
    },
    async copyUrl(payment_link) {
      try {
        await navigator.clipboard.writeText(payment_link);
        this.$toast.success('Link copiado com sucesso!', { position: 'bottom-center' });
      } catch ($e) {
        this.$toast.error('Error ao copiar o link', { position: 'top-center' });
      }
    },
    async sendWhatsApp(link_payment_id) {
      this.$swal.fire({
        icon: 'question',
        title: 'Insira o numero de whatsapp que deseja enviar:',
        input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Enviar',
        preConfirm: async (value) => {
          await this.$store
            .dispatch('SEND_WHATSAPP_PAYMENT_LINK_SNAILCOIN', {
              link_payment_id,
              whatsapp_number: value
            })
            .then((response) => {
              this.$swal({
                title: response.success,
                icon: 'success'
              });
            })
            .catch(() => {
              this.$swal.showValidationMessage(`Ocorreu um problema.`);
            });
        },
        onOpen: function () {
          $('#swal-input').focus();
        },
        inputValidator: (value) => {
          if (!value) {
            return 'Insira um valor';
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    }
  }
};
</script>

<style></style>
